<template>
  <section class="bannerBox">
	  <div class="bannerContent">
		  <!-- <h2>인기 게임 쿠폰</h2> -->
		  <p>长沙奇葩斗斗网络科技有限公司专注于手机和网页游戏开发运营，以bt游戏发行作为公司的主营业务，深耕中国动漫ip领域，将国风动漫ip与手机游戏相结合，致力于为玩家提供超高性价比和极致的游戏体验。《武道将魂》是我们推出一款新游，在这里可以享受最丰富的福利，带来不一样的游戏体验。</p>
		  <!-- <a>모두 선택하여 구매</a> -->
		  <p>《武道将魂（无限合宠服）》是一款回合制游戏。这是你从没见过的回合制游戏！极速节奏，超高爆率，前爽未有的冒险之旅！上线就送GM工具，免费激活首充等各种高回报活动！还有全圣兽礼包等你免费解锁！还可将普通泡泡卡直接魔改成超圣兽变异泡泡！各种活动福利免费拿！还有各色极品召唤兽、神兽、伙伴、坐骑，等你来驾驭。随便培养战力轻松破千万！还等什么快来体验吧！</p>
	  </div>
  </section>
  <!-- <section class="richText bg1">
  	  <div class="richTextContent">
  		  <h2>您可以在这里享受最高的福利</h2>
  		  <p>이것은 인기 게임 교환 코드의 스토어입니다. 관심 있다면 100원만 있으면 게임 내 혜태 아이템을 획득할 수 있습니다.</p>
  	  </div>
  </section> -->
  <section class="bg1">
	  <div class="listBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		  <h2 class="title">游戏</h2>
		  <div class="listItem">
			  <ul>
				<template v-for="(item,index) in goodList" :key="index">
					<li class="item">
						<div class="imgBox">
							<a href="javascript:;">
							<router-link :to="'/products/detail/'+item.id">
								<img src="https://qpyx-rlsb.oss-cn-shanghai.aliyuncs.com/2024/09/13/34a6fd3168854cbd8effcd32ab7c2299.png" />
								<!-- <img src="https://qpyx-rlsb.oss-cn-shanghai.aliyuncs.com/2024/09/24/32b8fffff0f4450fb09cad75ba574704.jpg" /> -->
							</router-link>
							</a>
							<!-- <span class="tag">강시</span> -->
						</div>
						<div class="desBox">
							<h3 class="title">
								<router-link :to="'/products/detail/'+item.id">
								<!-- {{item.title}} -->
								武道将魂-无限合宠刷充服
								</router-link>
								<!-- <a href="javascript:;">漫斗纪元</a> -->
							</h3>
							<p class="price">
								<!-- <span class="original">¥{{item.originalPrice}} CNY</span>
								<span class="onsale">¥{{item.presentPrice}} CNY</span> -->
								<router-link :to="'/products/detail/'+item.id"><span class="onsale">立即试玩</span></router-link>
							</p>
						</div>
					</li>
				</template>
			  </ul>
		  </div>
		  
		  <!-- <swiper
		    :effect="'coverflow'"
			:grabCursor="true"
			:centeredSlides="true"
			:slidesPerView="'auto'"
			:coverflowEffect="{
			  rotate: 50,
			  stretch: 0,
			  depth: 100,
			  modifier: 1,
			  slideShadows: false,
			}"
			:pagination="true" 
		  	:style="{
		  	  '--swiper-pagination-color': '#000'
		  	}"
		    class="mySwiper"
		    >
		    <swiper-slide v-for="(item,index) in adList" :key="index">
		  		<div class="ad">
		  			<img :src="item.url">
		  		</div>
		  	</swiper-slide>
		  </swiper> -->
	  </div>
  </section>

</template>

<script setup>
import { ref, onMounted } from "vue";
import { getGoodList } from '@/api/index'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isMobile } from '@/utils/common.js'

import SwiperCore, { EffectCoverflow,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([EffectCoverflow,Pagination,Navigation]);

import "swiper/components/effect-coverflow";

const adList = ref([
	{ url: require('@/assets/images/ad1.png') },
	{ url: require('@/assets/images/ad2.png') },
	{ url: require('@/assets/images/ad3.png') },
	{ url: require('@/assets/images/ad4.png') }
])

const goodList = ref([])

onMounted(()=>{
	getGoodList().then((res)=>{
		goodList.value = res.rows
	})
})

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.bannerBox{
	width: 100%;min-height: 26rem;overflow: hidden;
	background: url('~@/assets/images/banner.jpg') no-repeat center top;
	background-size: 100%;position: relative;z-index: 1;
	&::after{
		content: '';display: block;position: absolute;top: 0;left: 0;
		width: 100%;height: 100%;background: rgba(18,18,18,0.2);z-index: 111;
		text-align: center;line-height: 26rem;color: #efefef;
		font-size: 2.4rem;font-weight: 400;
	}
	.bannerContent{
		position: absolute;color: #fff;text-align: center;z-index: 1111;
		left: 50%;bottom: 6rem;transform: translate(-50%,0);
		h2{
			font-size: 5.2rem;font-weight: 400;
		}
		p{
			margin-top: 1rem;font-size: 1.6rem;
		}
		a{
			display: inline-block;padding: 1.5rem 3rem;border: 1px solid #fff;color: #fff;
			letter-spacing: 0.1rem;margin-top: 2rem;box-sizing: border-box;font-size: 1.6rem;
			transition: box-shadow 0.1s ease;
			&:hover{
				box-shadow: 0 0 2px rgb(255,255,255);
			}
		}
	}
}
.richText{
	padding: 40px;box-sizing: border-box;padding-bottom: 0;
	.richTextContent{
		width: 100%;text-align: center;
		padding: 0 5rem;margin: 0 auto;box-sizing: border-box;
		h2{
			font-size: 2.4rem;font-weight: 400;
		}
		p{
			margin-top: 2rem;color: rgba(18,18,18,0.75);font-size: 1.6rem;
		}
	}
}
.mySwiper{
	margin-top: 3rem;
	.ad{
		position: relative;width: 100%;height: 170px;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 0;left: 50%;transform: translate(-50%,0);
		}
	}
}
</style>
